import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      restricted: false,
      breadcrumb: [],
    },
    component: Home,
  },
  {
    path: "/monitor",
    name: "Monitor",
    meta: {
      restricted: false,
      breadcrumb: [],
    },
    component: () => import("../views/monitor/Monitor"),
  },
  {
    path: "/p/schedule",
    name: "admin.schedule",
    meta: {
      name: "Schedule",
      restricted: true,
      breadcrumb: [
        {
          id: 1,
          name: "Home",
          route: "/p",
        },
      ],
    },
    component: () => import("../views/admin/schedule/Schedule"),
  },
  {
    path: "/p",
    name: "Dashboard",
    meta: {
      restricted: true,
      name: "Home",
      breadcrumb: [],
    },
    component: () => import("../views/admin/Layout.vue"),
    children: [
      {
        path: "",
        name: "admin.dashboard",
        meta: {
          name: "Dashboard",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              router: "/p",
            },
          ],
        },
        component: () => import("../views/admin/dashboard/Dashboard"),
      },
      {
        path: "changelog",
        name: "admin.changelog",
        meta: {
          name: "Change Log",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              router: "/p",
            },
          ],
        },
        component: () => import("../views/admin/changelog/changelog"),
      },
      {
        path: "my-jobs",
        name: "customer.jobs",
        meta: {
          name: "Jobs",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              router: "/p",
            },
          ],
        },
        component: () => import("../views/customer/jobs/Jobs"),
      },
      {
        path: "my-companies",
        name: "customer.clients",
        meta: {
          name: "Companies",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              router: "/p",
            },
          ],
        },
        component: () => import("../views/customer/clients/Clients"),
      },

      {
        path: "products",
        name: "admin.products",
        meta: {
          name: "Products",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/product/Products.vue"),
      },
      {
        path: "products/:id",
        props: true,
        name: "admin.products.view",
        meta: {
          name: "Products",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Products",
              route: "/p/products",
            },
          ],
        },
        component: () => import("../views/admin/product/Product.vue"),
      },
      {
        path: "companies",
        name: "admin.companies",
        meta: {
          name: "Companies",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/company/Companies.vue"),
      },
      {
        path: "companies/:id",
        props: true,
        name: "admin.companies.view",
        meta: {
          name: "Companies",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Companies",
              route: "/p/companies",
            },
          ],
        },
        component: () => import("../views/admin/company/Company.vue"),
      },
      {
        path: "map",
        name: "admin.map",
        meta: {
          name: "Map",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/map/Map.vue"),
      },
      {
        path: "tabletmap",
        name: "admin.tabletmap",
        meta: {
          name: "Map",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/map/TabletMap"),
      },
      // {
      //   path: 'profile',
      //   name: 'admin.profile',
      //   meta: {
      //     name: 'Profile',
      //     breadcrumb: [
      //       {
      //         id: 1,
      //         name: "Home",
      //         route: "/p"
      //       }
      //     ]
      //   },
      //   component: () => import('../views/admin/profile/Profile')
      // },
      {
        path: "timesheets",
        name: "admin.timesheets",
        meta: {
          name: "Timesheets",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/timesheets/Timesheets.vue"),
      },
      {
        path: "vehicles",
        name: "admin.vehicles",
        meta: {
          name: "Vehicles",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/vehicles/Vehicles.vue"),
      },
      {
        path: "vehicles/:id",
        name: "admin.vehicles.view",
        props: true,
        meta: {
          name: "Vehicles",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Vehicles",
              route: "/p/vehicles",
            },
          ],
        },
        component: () => import("../views/admin/vehicles/Vehicle.vue"),
      },
      {
        path: "vehicles/:id/inspection",
        name: "admin.vehicles.inspection",
        props: true,
        meta: {
          name: "Inspection",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Vehicles",
              route: "/p/vehicles",
            },
            {
              id: 3,
              name: "Vehicle",
            },
          ],
        },
        component: () => import("../views/admin/vehicles/Inspection.vue"),
      },
      {
        path: "/google2fa",
        name: "google2fa",
        component: () => import("../views/admin/auth/GoogleTwoFactor"),
        props: true,
      },
      {
        path: "/twofactor",
        name: "twoFactorAuth",
        component: () => import("../views/admin/auth/Authenticate"),
        props: true,
      },
      {
        path: "checklist",
        name: "admin.checklists",
        meta: {
          name: "Checklists",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/checklists/Checklists.vue"),
      },
      {
        path: "checklist/:id",
        name: "admin.checklists.view",
        props: true,
        meta: {
          name: "Checklist",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Checklists",
              route: "/p/checklist",
            },
          ],
        },
        component: () => import("../views/admin/checklists/Checklist.vue"),
      },
      {
        path: "brands",
        name: "admin.brands",
        meta: {
          name: "Brands",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/brands/Brands.vue"),
      },
      {
        path: "brands/:id",
        name: "admin.brands.view",
        props: true,
        meta: {
          name: "Brand",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Brands",
              route: "/p/brands",
            },
          ],
        },
        component: () => import("../views/admin/brands/Brand.vue"),
      },
      {
        path: "jobs",
        name: "admin.jobs",
        meta: {
          name: "Jobs",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/jobs/Jobs.vue"),
      },
      {
        path: "nearest",
        name: "admin.nearestjobs",
        meta: {
          name: "Nearest Jobs",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/jobs/NearestJobs.vue"),
      },
      {
        path: "users/:id/jobs",
        name: "admin.userjobs",
        props: true,
        meta: {
          name: "User Jobs",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/jobs/UserJobs.vue"),
      },
      {
        path: "jobs/:id",
        name: "admin.jobs.view",
        props: true,
        meta: {
          name: "Jobs",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Jobs",
              route: "/p/jobs",
            },
          ],
        },
        component: () => import("../views/admin/jobs/Job.vue"),
      },
      {
        path: "sites",
        name: "admin.sites",
        meta: {
          name: "Sites",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/sites/Sites.vue"),
      },
      {
        path: "sites/:id",
        name: "admin.sites.view",
        props: true,
        meta: {
          name: "Sites",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Sites",
              route: "/p/sites",
            },
          ],
        },
        component: () => import("../views/admin/sites/Site.vue"),
      },
      {
        path: "clients",
        name: "admin.clients",
        meta: {
          name: "Clients",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/clients/Clients.vue"),
      },
      {
        path: "clients/:client_id/services/:id",
        name: "admin.clients.services.view",
        props: true,
        meta: {
          name: "Services",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Clients",
              route: "/p/clients",
            },
            {
              id: 3,
              name: "Client",
            },
          ],
        },
        component: () =>
          import("../views/admin/clients/ClientMonitoringService"),
      },
      {
        path: "clients/:client_id/rules",
        name: "admin.clients.rules.view",
        props: true,
        meta: {
          name: "Rules",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Clients",
              route: "/p/clients",
            },
            {
              id: 3,
              name: "Client",
            },
          ],
        },
        component: () => import("../views/admin/clients/ClientMonitoringRules"),
      },
      {
        path: "clients/:id",
        name: "admin.clients.view",
        props: true,
        meta: {
          name: "Client",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
            {
              id: 2,
              name: "Clients",
              route: "/p/clients",
            },
          ],
        },
        component: () => import("../views/admin/clients/Client.vue"),
      },
      {
        path: "management",
        name: "admin.management",
        meta: {
          name: "Management",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/management/Management.vue"),
        children: [
          {
            path: "users",
            name: "admin.management.users",
            meta: {
              name: "Users",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
              ],
            },
            component: () =>
              import("../views/admin/management/users/Users.vue"),
          },
          {
            path: "users/:id",
            name: "admin.management.users.view",
            props: true,
            meta: {
              name: "User",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
                {
                  id: 3,
                  name: "Users",
                  route: "/p/management/users",
                },
              ],
            },
            component: () => import("../views/admin/management/users/User.vue"),
          },
          {
            path: "roles",
            name: "admin.management.roles",
            meta: {
              name: "Roles",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
              ],
            },
            component: () =>
              import("../views/admin/management/roles/Roles.vue"),
          },
          {
            path: "roles/:id",
            name: "admin.management.roles.view",
            props: true,
            meta: {
              name: "Role",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
                {
                  id: 3,
                  name: "Roles",
                  route: "/p/management/roles",
                },
              ],
            },
            component: () => import("../views/admin/management/roles/Role.vue"),
          },
          {
            path: "permissions",
            name: "admin.management.permissions",
            meta: {
              name: "Permissions",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
              ],
            },
            component: () =>
              import("../views/admin/management/permissions/Permissions.vue"),
          },
          {
            path: "permissiongroups",
            name: "admin.management.permissiongroups",
            meta: {
              name: "Permission Groups",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
              ],
            },
            component: () =>
              import(
                "../views/admin/management/permissiongroups/PermissionGroups.vue"
              ),
          },
          {
            path: "permissiongroups/:id",
            name: "admin.management.permissiongroups.view",
            props: true,
            meta: {
              name: "Permission Group",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Management",
                  route: "/p/management",
                },
                {
                  id: 3,
                  name: "Permission Groups",
                  route: "/p/management/permissiongroups",
                },
              ],
            },
            component: () =>
              import(
                "../views/admin/management/permissiongroups/PermissionGroup.vue"
              ),
          },
        ],
      },
      {
        path: "reports",
        name: "admin.reports",
        meta: {
          name: "Reports",
          breadcrumb: [
            {
              id: 1,
              name: "Home",
              route: "/p",
            },
          ],
        },
        component: () => import("../views/admin/reports/Reports"),
        children: [
          {
            path: "jobsheets",
            name: "admin.reports.jobsheets",
            meta: {
              name: "Jobsheets Report",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Reports",
                },
              ],
            },
            component: () =>
              import("../views/admin/reports/Jobsheets/Reports.vue"),
          },
          {
            path: "faultysites",
            name: "admin.reports.FaultySites",
            meta: {
              name: "Fault Sites Report",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Reports",
                },
              ],
            },
            component: () =>
              import("../views/admin/reports/FaultySites/FaultySites"),
          },
          {
            path: "completedjobs",
            name: "admin.reports.CompletedJobs",
            meta: {
              name: "Completed Jobs Report",
              breadcrumb: [
                {
                  id: 1,
                  name: "Home",
                  route: "/p",
                },
                {
                  id: 2,
                  name: "Reports",
                },
              ],
            },
            component: () =>
              import("../views/admin/reports/CompletedJobs/CompletedJobs"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
