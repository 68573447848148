import { createStore } from 'vuex'
import auth from './modules/auth';
import system from './modules/system';

export default createStore({
  state() {
    return {

    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    system
  }
})
