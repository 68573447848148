import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import Toasts from './mixins/Toasts';
import Permissions from './mixins/Permissions';
import VueAutosuggest from "vue-autosuggest";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

let cancelSource = axios.CancelToken.source();

const requestInterceptor = config => {
  config.cancelToken = cancelSource.token;
  return config;
};

axios.interceptors.request.use(requestInterceptor);
// axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.validateStatus = (status) => {

    return status >= 200 && status < 300;
};

router.beforeEach((to, from, next) => {
  cancelSource.cancel("Cancelled by User.");
  cancelSource = axios.CancelToken.source();
  next();
});

store.dispatch('auth/checkToken').then(() => {

  // Check user is logged in before loading restricted route
  router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.restricted) && store.state.auth.user === null){
      next('/')
    }else{
      next()
    }
  });

  axios.interceptors.response.use((response) => {
    return response;
  }, error => {
    if(!error.response){
        // return Promise.reject(error);
        return error;
    }
    if(error.response.status === 401){
        if(error.response.data.message === "Unauthenticated." && window.location.pathname !== "/"){
            this.$store.commit('SET_AUTHENTICATED', false);
            this.$store.commit('SET_USER', null);
            window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
        }
    }

    if(error.response.status === 403){
        if(error.response.data !== undefined){
            if(error.response.data['2fa'] === 'required'){
                console.info("2FA IS REQUIRED");
                router.replace({name: 'google2fa', params: {
                        'route': router.currentRoute.name
                    }});
                return error;
            }
            if(error.response.data['2fa'] === 'expired'){
                console.info("2FA IS REQUIRED");
                router.replace({name: 'twoFactorAuth', params: {
                        'route': router.currentRoute.name
                    }});
                return error;
            }
        }
        return Promise.reject(error);
    }

    if(error.response.status === 404){
        return {error: error, status: 404};
    }

    if(axios.isCancel(error)){
        console.log("Error is due to cancellation by user.");
    }
    return Promise.reject(error);
    // return error;
  });

  let app = createApp(App).use(store).use(router).use(VueAutosuggest).use(VueViewer);

  app.config.globalProperties.axios=axios;
  app.mixin(Toasts)
    app.mixin(Permissions)
  app.mount('#app')

});