<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    methods: {
        $success(msg){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: `${msg}`
            });
        },
        $error(msg, error = null){
            if(error !== null){
                if(axios.isCancel(error)) return;
                if(error.message === 'error.response is undefined') return;
                console.log(error);

                if(error.response){
                  if(error.response.status == 403){
                    msg = "You do not have permission to complete this action!";
                  }
                }
            }

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'error',
                title: `${msg}`
            });
        },
        $warning(msg){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'warning',
                title: `${msg}`
            });
        },
        $info(msg){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'info',
                title: `${msg}`
            });
        },
        $confirm(msg, title = 'Are you sure?', options = {}){
            return new Promise((resolve) => {
                Swal.fire({
                    title: title,
                    text: msg,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: options.confirmButtonText || 'Yes',
                    cancelButtonText: options.cancelButtonText || 'No',
                }).then((result) => {
                    if(result.isConfirmed){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                });
            });
        },
    }
};
</script>
