<template>
  <div class="wrapper">
    <div
      class="
        section-authentication-signin
        d-flex
        align-items-center
        justify-content-center
        my-5 my-lg-0
      "
    >
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
          <div class="col mx-auto">
            <div class="mb-4 text-center">
              <!-- <img src="assets/images/logo-img.png" width="180" alt="" /> -->
              <p class="text-white font-bold font-35">AD Systems - Link</p>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="border p-4 rounded">
                  <div v-if="reset_token === null" class="form-body">
                    <form class="row g-3">
                      <div class="col-12">
                        <label for="inputEmailAddress" class="form-label"
                          >Email Address</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="inputEmailAddress"
                          placeholder="Email Address"
                          v-model="email"
                        />
                      </div>
                      <div class="col-12">
                        <label for="inputChoosePassword" class="form-label"
                          >Enter Password</label
                        >
                        <div class="input-group" id="show_hide_password">
                          <input
                            :type="passwordFieldType"
                            class="form-control border-end-0"
                            v-model="password"
                            id="inputChoosePassword"
                            placeholder="Enter Password"
                          />
                          <a
                            href="javascript:;"
                            @click="showPassword = !showPassword"
                            class="input-group-text bg-transparent"
                            ><i
                              :class="{
                                'bx bx-hide': !showPassword,
                                'bx bx-show': showPassword,
                              }"
                            ></i
                          ></a>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                      <div class="col-md-6 text-end">
                        <!-- <a href="authentication-forgot-password.html"
                          >Forgot Password ?</a
                        > -->
                      </div>
                      <div v-if="authenticationError" class="col-12 mt-3">
                          <div class="d-grid">
                            <p class="text-danger">Unable to sign in! Please make sure you entered the correct username and password.</p>
                          </div>
                        </div>
                      <div class="col-12">
                        <div v-if="!authenticating" class="d-grid">
                          <button type="submit" class="btn btn-light" @click="signIn">
                            <i class="bx bxs-lock-open"></i>Sign in
                          </button>
                        </div>
                        <div v-else class="d-grid text-white text-center">
                          <div class="spinner-border mx-auto" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div v-else class="form-body">
                    <form class="row g-3">
                      <div class="col-12">
                        <label for="inputChoosePassword" class="form-label"
                          >Enter New Password</label
                        >
                        <div class="input-group" id="show_hide_password">
                          <input
                            :type="passwordFieldType"
                            class="form-control border-end-0"
                            v-model="password"
                            id="inputChoosePassword"
                            placeholder="Enter Password"
                          />
                          <a
                            href="javascript:;"
                            @click="showPassword = !showPassword"
                            class="input-group-text bg-transparent"
                            ><i
                              :class="{
                                'bx bx-hide': !showPassword,
                                'bx bx-show': showPassword,
                              }"
                            ></i
                          ></a>
                        </div>
                      </div>
                      <div class="col-12">
                        <label for="inputChooseConfirmPassword" class="form-label"
                          >Confirm Password</label
                        >
                        <div class="input-group" id="show_hide_password">
                          <input
                            :type="passwordFieldType"
                            class="form-control border-end-0"
                            v-model="confirmPassword"
                            id="inputChooseConfirmPassword"
                            placeholder="Confirm Password"
                          />
                          <a
                            href="javascript:;"
                            @click="showPassword = !showPassword"
                            class="input-group-text bg-transparent"
                            ><i
                              :class="{
                                'bx bx-hide': !showPassword,
                                'bx bx-show': showPassword,
                              }"
                            ></i
                          ></a>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                      <div class="col-md-6 text-end">
                        <!-- <a href="authentication-forgot-password.html"
                          >Forgot Password ?</a
                        > -->
                      </div>
                      <div v-if="authenticationError" class="col-12 mt-3">
                          <div class="d-grid">
                            <p class="text-danger">Unable to reset password!</p>
                          </div>
                        </div>
                      <div class="col-12">
                        <div v-if="!authenticating" class="d-grid">
                          <button type="submit" class="btn btn-light" @click="resetPassword">
                            <i class="bx bxs-lock-open"></i>Reset Password
                          </button>
                        </div>
                        <div v-else class="d-grid text-white text-center">
                          <div class="spinner-border mx-auto" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      reset_token: null
    };
  },
  computed: {
    passwordFieldType: function () {
      return this.showPassword ? "text" : "password";
    },
    ...mapGetters({
      authenticating: "auth/authenticating",
      authenticationError: "auth/authenticationError",
      user: "auth/user"
    }),
  },
  methods: {
    ...mapActions({
      setToken: "auth/setToken",
      checkToken: "auth/checkToken",
      setUser: "auth/setUser"
    }),
    signIn() {
      this.setToken({ username: this.email, password: this.password });
    },
    resetPassword(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/users/reset`, {
        token: this.reset_token,
        password: this.password,
        confirm: this.confirmPassword
      })
      .then(() => {
        this.$success("Successfully reset password!");
        this.reset_token = null;
      })
      .catch(error => {
        this.$error("Failed to reset password!", error);
      })
    }
  },
  mounted(){
    if(this.$route.query.reset_token !== undefined){
        this.reset_token = this.$route.query.reset_token;
    }else{
      if(this.$route.query.token !== undefined){
        this.setUser({token: this.$route.query.token, allowFalse: true});
      }else{
        this.checkToken();
      }
    }
  },
  watch: {
    user(newUser){
      if(newUser !== null){
        if(this.$route.query.redirect !== undefined){
          this.$router.push({name: this.$route.query.redirect});
        }else{
          this.$router.push({name: 'admin.dashboard'});
        }
      }
    }
  }
};
</script>
