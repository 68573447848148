import axios from 'axios';
export default {
  namespaced: true,
  state() {
    return {
      token: null,
      user: null,
      permissions: [],
      roles: [],
      authenticating: false,
      authenticationError: false
    }
  },
  getters: {
    authenticating: state => {
      return state.authenticating;
    },
    authenticationError: state => {
      return state.authenticationError
    },
    user: state => {
      return {
        ...state.user,
        roles: state.roles,
        permissions: state.permissions
      };
    },
    token: state => {
      return state.token
    }
  },
  mutations: {
    // Mutations change the state
    setToken(state, value){
      state.token = value;
      localStorage.setItem('link::token', value);
      if(state.token !== null){
        axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      }
    },
    setUser(state, value){
      state.user = value;
    },
    setPermissions(state, value){
      state.permissions = value || []
    },
    setRoles(state, value){
      state.roles = value || []
    },
    setAuthenticating(state, value){
      state.authenticating = value;
    },
    setAuthenticationError(state, value){
      state.authenticationError = value;
    }
  },
  actions: {
    // Actions trigger mutations
    setToken({dispatch, commit}, credentials){
      commit('setAuthenticating', true);
      commit('setAuthenticationError', false);
      axios.post(`${process.env.VUE_APP_API_URL}/v1/auth/login`, credentials)
      .then(response => {
        dispatch('setUser', {token: response.data.token});
      })
      .catch(error => {
        console.error("Failed to authenticate", error);
        commit('setAuthenticating', false);
        commit('setAuthenticationError', true);
      });
    },
    setUser({commit}, {token, allowFalse = false}){
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return axios.get(`${process.env.VUE_APP_API_URL}/v1/auth/login`, {
        // headers: {
        //   authorization: `Bearer ${token}`
        // }
      })
      .then(response => {
        commit('setToken', token);
        commit('setUser', response.data.user);
        commit('setPermissions', response.data.permissions);
        commit('setRoles', response.data.roles);
        commit('setAuthenticating', false);
      })
      .catch(error => {
        console.error("Failed to authenticate", error);
        commit('setAuthenticating', false);
        commit('setAuthenticationError', !allowFalse);
      });
      
    },
    checkToken({dispatch, commit}){
      commit('setAuthenticating', true);
      commit('setAuthenticationError', false);

      let jwt = localStorage.getItem('link::token');
      if(jwt !== undefined){
          return dispatch('setUser', {token: jwt, allowFalse: true});
      }
    },
    logout({commit}){
      commit('setToken', null);
      commit('setUser', null);
      commit('setPermissions', []);
    }
  }
}