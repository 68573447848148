export default {
  namespaced: true,
  state() {
    return {
      module: "Support",
      modules: [
        "Support",
        "Admin",
        "Sales",
        "Service"
      ]
    }
  },
  getters: {
    getModule: state => {
      return state.module
    },
    getModules: state => {
      return state.modules
    }
  },
  mutations: {
    // Mutations change the state
    setModule(state, value){
      state.module = value;
    }
  },
  actions: {
    // Actions trigger mutations
    setModule({commit}, module){
      commit('setModule', module);
    }
  }
}