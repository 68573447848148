<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/user',
    })
  },
  methods: {
    $can(permissionName) {
      if(!this.loggedInUser.permissions) return false;
      return this.loggedInUser.permissions.indexOf(permissionName) !== -1;
    },
    $canAny(...permissions){
      if(!this.loggedInUser.permissions) return false;
      for(let p of permissions){
        if(this.loggedInUser.permissions.indexOf(p) !== -1){
          return true;
        }
      }
      return false;
    },
    $canAll(...permissions){
      if(!this.loggedInUser.permissions) return false;
      for(let p of permissions){
        if(this.loggedInUser.permissions.indexOf(p) === -1){
          return false;
        }
      }
      return true;
    },
    $hasRole(roleName){
      if(!this.loggedInUser.roles) return false;
      return this.loggedInUser.roles.indexOf(roleName) !== -1;
    },
    $hasAllRoles(...roles){
      if(!this.loggedInUser.roles) return false;
      for(let r of roles){
        if(this.loggedInUser.roles.indexOf(r) === -1){
          return false;
        }
      }
      return true;
    },
    $hasOneOfRoles(...roles){
      if(!this.loggedInUser.roles) return false;
      for(let r of roles){
        if(this.loggedInUser.roles.indexOf(r) !== -1){
          return true;
        }
      }
      return false;
    }
  },
};
</script>
